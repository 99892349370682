import React from 'react';
import ReactGA from "react-ga4";
import { Routes, Route, Navigate } from 'react-router-dom';
import './scss/styles.scss';
import ScrollToTop from './helpers/ScrollToTop';
import ErrorPage from './components/error.component';
import Header from './components/header.component';
import Footer from './components/footer.component';
import Modal from './components/modal.component';
import MainPage from './components/mainpage.component';
import InfoPage from './components/infopage.component';
import DigestPage from './components/digestpage.component';
import LearnPage from './components/learnpage.component';
// import Chatbot from './components/chatbot.component';
import config from './config.json';

export default function App() {

  ReactGA.initialize(config.GAid);
 
  return (
    <>
      <div className="wrapper">
        <header className="s-header">
          <Header />
        </header>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<MainPage />} />
          <Route exact path="/infographics" element={<InfoPage />} />
          <Route exact path="/digests" element={<DigestPage />} />
          <Route exact path="/learn" element={<LearnPage />} />
          <Route exact path="/books" element={<Navigate to="/learn" replace /> } />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <footer className="s-footer">
          <Footer />
        </footer>
      </div>
      <Modal />
    </>
  );
}
