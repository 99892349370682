import { useState, useEffect } from "react";
import { useLocation } from 'react-router';
import Intro from './intro.component';
import Cards from './cards.component';
import Form from './form.component';
import Video from './video.component';
// import { useGAPageTracker } from '../helpers/UseGAEventTracker';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const MainPage = (props) => {

  const [net, setNet] = useState('');
  const [text, setText] = useState('');
   
  const location = useLocation();

  // useGAPageTracker(window.location.pathname + window.location.search, "NFT2 main page view");

  useEffect(()=> {
    setNet(new URLSearchParams(window.location.search).get('network'));
    setText(new URLSearchParams(window.location.search).get('search'));
  }, []);

  useEffect(()=> {
    getMetaTags();
  });

  const getMetaTags = (network, search) => {

    let description;
    let title = "NFT 2.0 Aggregator by Envelop";
    let loadingText = "Loading..";
    const descStart = "The service collects data";
    const descEnd = "in the NFT 2.0 sphere, also known as Utility NFTs, Smart NFTs, and programmable NFTs.";

    if(!network) {
      network = net
    }
    if(!search) {
      search = text
    }

    if(network && !search) {
      title = `${network} projects on ${title}`;
      description = `${descStart} on ${network} chain projects ${descEnd}`;
      loadingText = `Loading ${network} projects..`;
    }
    else if(search) {
      title = `${search} projects on ${title}`;
      description = `${descStart} on ${search} projects ${descEnd}`;
      loadingText = `Searching ${search} projects..`;
    }
    else {
      description = `${descStart} on all projects ${descEnd}`;
    } 

    return { title, description, loadingText }
  }

  return (
    <>
      <main className="s-main">
        <Intro />
        <Cards location={location} getMetaTags={getMetaTags} {...props} />
        <div className="divider right"></div>
        <Video />
        <div className="divider left"></div>
        <Form />
      </main>
    </>
  );
}

export default MainPage;