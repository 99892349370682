import React from 'react';

class FilterTech extends React.Component {

    constructor() {
        super();
        this.state = {
            filterByProtocol: true,
            filterByOracle: true,
            filterByIndex: true
        };
    }
    
    render() {
    
        return (
            <div className="filter-tech mb-4"> 
                <label className="checkbox mr-4"> 
                <input type="checkbox" name="technology" value="protocol" onClick={() => this.setState({filterByProtocol: !this.state.filterByProtocol})} onChange={()=>{this.props.filterByTech([!this.state.filterByProtocol,this.state.filterByOracle,this.state.filterByIndex])}} checked={(this.state.filterByProtocol)} /><span className="check"></span><span className="check-text">Protocol</span>
                </label>
                <label className="checkbox mr-4"> 
                <input type="checkbox" name="technology" value="oracle" onClick={() => this.setState({filterByOracle: !this.state.filterByOracle})} onChange={()=>{this.props.filterByTech([this.state.filterByProtocol,!this.state.filterByOracle,this.state.filterByIndex])}} checked={(this.state.filterByOracle)} /><span className="check"></span><span className="check-text">Oracle</span>
                </label>
                <label className="checkbox mr-4"> 
                <input type="checkbox" name="technology" value="index" onClick={() => this.setState({filterByIndex: !this.state.filterByIndex})} onChange={()=>{this.props.filterByTech([this.state.filterByProtocol,this.state.filterByOracle,!this.state.filterByIndex])}} checked={(this.state.filterByIndex)} /><span className="check"></span><span className="check-text">Index</span>
                </label>
            </div>
        );
    }
}

export default FilterTech;