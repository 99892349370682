import { Timeline } from 'react-twitter-widgets';
import Bgimg from '../pics/bg/bg-lg-right-5.svg';
import Youtube from '../pics/socials/youtube.svg';
import LinkedIn from '../pics/socials/linkedin.svg';
import Github from '../pics/socials/github.svg';
import Telegram from '../pics/socials/telegram.svg';
import Twitter from '../pics/socials/twitter.svg';
import Medium from '../pics/socials/medium.svg';
import Facebook from '../pics/socials/facebook.svg';
import Instagram from '../pics/socials/instagram.svg';
import Tiktok from '../pics/socials/tiktok.svg';
import Discord from '../pics/socials/discord.svg';
import DeWeb from '../pics/socials/nft-news.svg';

const Form = () => (
    <section className="sec-form"><img className="sec-bg bg-right d-none d-xl-block" src={ Bgimg } alt="" />
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <h2>Do you want to join the Envelop <span className="text-grad">NFT 2.0 aggregator</span>?</h2>
        <form>
          <div className="form-apply"><a className="btn" href="https://docs.google.com/forms/d/1GozGkoQI91AvIyozYEEFj1SkQjya-nbL41oPHv3Qd14/viewform" rel="noreferrer" target="_blank">Add your project</a></div>
        </form>
        <div className="form__socials">
          <ul className="socials">
            <li><a href="https://t.me/envelop_en" rel="noreferrer" target="_blank" title="Telegram"><img src="/pics/socials/telegram.svg" alt="ENVELOP telegram group" /></a></li>
            <li><a href="https://www.youtube.com/@envelop?sub_confirmation=1" rel="noreferrer" target="_blank" title="YouTube"><img src={ Youtube } alt="ENVELOP. NFTs YouTube Channel" /></a></li>
            <li><a href="https://www.linkedin.com/company/niftsy" rel="noreferrer" target="_blank" title="LinkedIn"><img src={ LinkedIn } alt="NIFTSY is token" /></a></li>
            <li><a href="https://t.me/envelop_rus" rel="noreferrer" target="_blank" title="Telegram (Russia)"><img src={ Telegram } alt="ENVELOP telegram group (Russia)" /></a></li>
            <li><a href="https://github.com/niftsy/niftsysmarts" rel="noreferrer" target="_blank" title="Github"><img src={ Github } alt="Github of our NFT project" /></a></li>
            <li><a href="https://www.tiktok.com/@envelop.is" rel="noreferrer" target="_blank" title="TikTok"><img src={ Tiktok } alt="ENVELOP TikTok Channel" /></a></li>
            <li><a href="https://Instagram.com/envelop_project" rel="noreferrer" target="_blank" title="Instagram"><img src={ Instagram } alt="Instagram envelop.project" /></a></li>
            <li><a href="https://discord.gg/gtYcjqq76f" rel="noreferrer" target="_blank" title="Discord"><img src={ Discord } alt="ENVELOP Discord group" /></a></li>
            <li> <a href="https://envelop.medium.com/" rel="noreferrer" target="_blank" title="Medium"><img src={ Medium } alt="Blog about Web 3.0" /></a></li>
            <li><a href="https://twitter.com/Envelop_project" rel="noreferrer" target="_blank" title="Twitter"><img src={ Twitter } alt="Our twitter" /></a></li>
            <li><a href="https://www.facebook.com/profile.php?id=100089484795648" rel="noreferrer" target="_blank" title="Facebook"><img src={ Facebook } alt="ENVELOP Facebook" /></a></li>
            <li><a href="https://t.me/nonfungible_web" rel="noreferrer" target="_blank" title="NFT 2.0 News"><img src={ DeWeb } alt="NFT 2.0 News" /></a></li>
          </ul>
        </div>
      </div>
        <div className="col-lg-4">
          <Timeline
            dataSource={{
              sourceType: 'profile',
              screenName: 'Envelop_project'
            }}
            options={{
              height: '550'
            }}
          />
        </div>
      </div>
    </div>
  </section>
);

export default Form;