import ReactGA from "react-ga4";

export const useGAPageTracker = (path = "/", title = "Default title") => {
    // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: path, title: title });
}

export const useGAEventTracker = (category = "Category") => {
  const eventTracker = (action = "Action", label = "Label") => {
    // Send a custom event
    ReactGA.event({category: category, action: action, label: label});
  }
  return eventTracker;
}
