import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BgLeftimg from '../pics/bg/bg-lg-left-1.svg';
import BgRightimg from '../pics/bg/bg-lg-right-4.svg';

import Youtube from '../pics/socials/youtube.svg';
import LinkedIn from '../pics/socials/linkedin.svg';
import Facebook from '../pics/socials/facebook.svg';
import Instagram from '../pics/socials/instagram.svg';
import Github from '../pics/socials/github.svg';
import Discord from '../pics/socials/discord.svg';
import Telegram from '../pics/socials/telegram.svg';
import TelegramDeweb from '../pics/socials/nft-news.svg';
import Tiktok from '../pics/socials/tiktok.svg';
import Twitter from '../pics/socials/twitter.svg';
import Medium from '../pics/socials/medium.svg';

const ErrorPage = () => (
  <>
    <Helmet>
        <title>Error - NFT 2.0 by Envelop</title>
        <meta name="description" content="" />
    </Helmet>
    <main className="s-main">
      <div className="sec-error"><img className="sec-bg bg-left d-none d-lg-block" src={ BgLeftimg } alt="" /><img className="sec-bg bg-right d-none d-lg-block" src={ BgRightimg } alt="" />
        <div className="sec-error__text">
          <h1>ERROR 404 </h1>
          <div className="h1_sub text-grad">PAGE NOT FOUND</div>
          <p>We couldn't find the page you're looking for.</p>
          <p className="actions"> <Link to="/" rel="noopener noreferrer">Return Home</Link><span>|</span><Link to="/digests" rel="noopener noreferrer">Read our Digests</Link><span>|</span><a href="https://app.envelop.is/" target="_blank" rel="noreferrer">Launch the App</a></p>
          <ul className="socials">
            <li><a href="https://t.me/envelop_en" rel="noreferrer" target="_blank" title="Telegram"><img src={ Telegram } alt="ENVELOP telegram group" /></a></li>
            <li><a href="https://www.youtube.com/@envelop?sub_confirmation=1" rel="noreferrer" target="_blank" title="YouTube"><img src={ Youtube } alt="ENVELOP. NFTs YouTube Channel" /></a></li>
            <li><a href="https://www.linkedin.com/company/niftsy" rel="noreferrer" target="_blank" title="LinkedIn"><img src={ LinkedIn } alt="NIFTSY is token" /></a></li>
            <li><a href="https://t.me/envelop_rus" rel="noreferrer" target="_blank" title="Telegram (Russia)"><img src={ Telegram } alt="ENVELOP telegram group (Russia)" /></a></li>
            <li><a href="https://github.com/niftsy/niftsysmarts" rel="noreferrer" target="_blank" title="Github"><img src={ Github } alt="Github of our NFT project" /></a></li>
            <li><a href="https://www.tiktok.com/@envelop.is" rel="noreferrer" target="_blank" title="TikTok"><img src={ Tiktok } alt="ENVELOP TikTok Channel" /></a></li>
            <li><a href="https://Instagram.com/envelop_project" rel="noreferrer" target="_blank" title="Instagram"><img src={ Instagram } alt="Instagram envelop.project" /></a></li>
            <li><a href="https://discord.gg/gtYcjqq76f" rel="noreferrer" target="_blank" title="Discord"><img src={ Discord } alt="ENVELOP Discord group" /></a></li>
            <li><a href="https://envelop.medium.com/" rel="noreferrer" target="_blank" title="Medium"><img src={ Medium } alt="Blog about Web 3.0" /></a></li>
            <li><a href="https://twitter.com/Envelop_project" rel="noreferrer" target="_blank" title="Twitter"><img src={ Twitter } alt="Our twitter" /></a></li>
            <li><a href="https://www.facebook.com/profile.php?id=100089484795648" rel="noreferrer" target="_blank" title="Facebook"><img src={ Facebook } alt="ENVELOP Facebook" /></a></li>
            <li><a href="https://t.me/nonfungible_web" rel="noreferrer" target="_blank" title="NFT 2.0 News"><img src={ TelegramDeweb } alt="NFT 2.0 News" /></a></li>
          </ul>
        </div>
      </div>
    </main>
  </>
);

export default ErrorPage;