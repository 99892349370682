import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useGAPageTracker, useGAEventTracker } from '../helpers/UseGAEventTracker';
import Bgimg from '../pics/bg/bg-lg-right-6.svg';
import config from '../config.json';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const InfoPage = () => {

    const [cats, setCats] = useState([]);
    const [activeCat, setActiveCat] = useState(0);
    // const [notActive, setNotActive] = useState(0);

    useGAPageTracker(window.location.pathname + window.location.search, "Infographics page view");
    const gaEventTracker = useGAEventTracker('Infographics');
   
    useEffect(() => {
        const _cats = [...new Set(Object.values(config.infographics).map(i => i.cat))];
        setCats(_cats.reverse());
        setActiveCat(_cats[0]);
    }, []);
  

    // const setExpand = (y) => {
    //     if(activeCat === y) {
    //         if(notActive) {
    //             setNotActive(0);
    //         }
    //         else {
    //             setNotActive(1);
    //         }
    //     }
    //     else {
    //         setNotActive(0);
    //         setActiveCat(y);
    //     }
    // }

    const filteredByCat = Object.values(config.infographics).filter(item => { return item.cat === activeCat });

    const infoCat = (y,k) => {
        
        return(
            <div className="accordion-container" key={k} onClick={ () => {}}>
                <div className={"panel " + ((activeCat === y) ? "active" : "")}>
                    <div className="heading"> <span>{ y }</span>
                        <svg className="arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"></path>
                        </svg>
                    </div>
                    <div className="content">
                        <div className="row">
                            { Object.keys([...Array(Number(Object.keys(filteredByCat).length))]).reverse().map((i,k) => infoLink(i,k)) }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const infoLink = (n,k) => {
            
        return(
            <div className="col-md-12 mb-4" key={k}>
                <figure>
                    <a href={filteredByCat[n]["path"] + filteredByCat[n]["image"]} onClick={()=>gaEventTracker(`infographics${n}`,'Open Infographics file')} target="_blank" rel="noreferrer"><img src={filteredByCat[n]["path"] + filteredByCat[n]["cover"]} alt={filteredByCat[n]["text"]} /><figcaption>{filteredByCat[n]["text"]}</figcaption></a>
                </figure>
            </div>
        );
    }

    return(
        <>
            <Helmet>
                <title>Infographics of NFT 2.0 by Envelop</title>
                <meta name="description" content="The most complete NFT 2.0-related market data and information." />
            </Helmet>
            <main className="s-main"> 
                <section className="sec-back"> 
                <div className="container">
                <Link to="/" className="btn btn-gray btn-sm"> 
                    <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.6668 9.16659H6.52516L11.1835 4.50825L10.0002 3.33325L3.3335 9.99992L10.0002 16.6666L11.1752 15.4916L6.52516 10.8333H16.6668V9.16659Z" fill="white"></path>
                    </svg><span>Back    </span></Link></div>
                </section>
                <section className="sec-info">
                    <div className="bg-gradient"></div><img className="sec-bg bg-right d-none d-xl-block" src={ Bgimg } alt="" />
                    <div className="container"> 
                        <div className="row">
                            <div className="col-lg-10">
                                <h1 className="mb-4">NFT 2.0 Infographics </h1>
                                <p className="mb-2">The most complete NFT 2.0-related market data and information.</p>
                                <p className="mt-0">Be the first to know about NFTs and NFT2.0 from different sectors and blockchains by subscribing to the blog <a href="https://envelop.medium.com" target="_blank" rel="noreferrer">envelop.medium.com</a></p>
                            </div>
                        </div>
                        <div className="info-list">
                            { cats.map((i,k) => infoCat(i,k)) }
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
};

export default InfoPage;