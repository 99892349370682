
const Video = () => (
  <section className="sec-video"> 
    <div className="container"> 
      <div className="row"> 
        <div className="col-lg-8 offset-lg-2"> 
          <div className="video-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/B9wdJ4ZX6pE" title="NFT 2.0 aggregator. First in Web 3.0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Video;