import Logo from '../pics/logo.svg';

const scrollToTop = () =>{
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

const Footer = () => (
  <div className="container">
  <div className="row align-items-center">
    <div className="col-4 col-lg-3 order-1">
      <div className="s-logo" onClick={scrollToTop}><img src={ Logo } alt="logo niftsy" /></div>
    </div>
    <div className="col-12 col-lg-6 order-3 order-lg-2">
      <div className="links">Connect with us — <a rel="nofollow" href="mailto:info@envelop.is">info@envelop.is</a> • <a href="https://github.com/niftsy/niftsysmarts" target="_blank" rel="noreferrer">Github</a> • <a href="https://envelop.medium.com" target="_blank" rel="noreferrer">Medium</a></div>
    </div>
    <div className="col-8 col-lg-3 order-2 order-lg-3"></div>
  </div>
</div>
);

export default Footer;