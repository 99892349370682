import React from 'react';

class SearchCard extends React.Component {
    
    render() {
        
        return (
            <div className="search-input w-100 mb-4">
                <input
                    className   = "input-control control-gray"
                    type        = "text"
                    placeholder = "Search project.." 
                    value       = { this.props.searchText }
                    onChange    = { (e) => this.props.setSearchText(e.target.value) }
                    onKeyDown   = { (e) => {
                        if (e.key === "Enter")
                            this.props.searchItem(e.target.value);
                        }
                    }
                />
                <div className="control-search" onClick={ () => (this.props.searchText) ? this.props.searchItem(this.props.searchText) : null }>Search</div>
            </div>
        );
    }
}

export default SearchCard;